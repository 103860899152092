<template>
  <button class="btn bg-red-500 border-transparent hover:bg-red-600 text-white text-sm" @click="logout">
    Uitloggen
  </button>
</template>

<script setup>
import useAuthLogout from '@/hooks/useAuthLogout'
const logout = useAuthLogout()
</script>
